import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Container } from 'components/misc';
import Post from './post';
import ArchiveAside from './aside';
import { media } from 'utils';

const FlexContainer = styled(Container)`
  display: flex;

  ${media.tablet`
    flex-direction: column;
  `}
`;

const Posts = styled.div`
  width: 400px;
  ${media.tablet`
    width: 100%;
  `}
`;

const StyledH2 = styled.h2`
  font-size: 2.25rem;
  color: ${({ theme })=> theme.color.green};
  margin-bottom: 34px;
  font-weight: 700;
`;

const Main = ()=> {
  const [activeDate, setActiveDate] = useState(undefined);
  const data = useStaticQuery(graphql`{
    posts: allDatoCmsPost(sort: {order: DESC, fields: meta___firstPublishedAt}, filter: {locale: {eq: "pl"}}) {
      nodes {
        title
        slug
        meta {
          firstPublishedAt
        }
      }
    }
  }
  `);

  const convertedPosts = {};

  data.posts.nodes.forEach((post)=> {
    if (!convertedPosts[post.meta.firstPublishedAt]) {
      convertedPosts[format(new Date(post.meta.firstPublishedAt), 'MM.yyyy')] = [post];
    } else {
      convertedPosts[format(new Date(post.meta.firstPublishedAt), 'MM.yyyy')].push(post);
    }
  });

  const setActive = (date)=> {
    setActiveDate(date);
  };

  useEffect(()=> {
    if (Object.keys(convertedPosts).length > 0) {
      setActive(Object.keys(convertedPosts)[0]);
    }
  }, []);

  return (
    <Section>
      <FlexContainer>
        <ArchiveAside posts={convertedPosts} activeDate={activeDate} setActiveDate={setActive} />
        <Posts>
          <StyledH2>{activeDate}</StyledH2>
          {activeDate && convertedPosts[activeDate]?.map(({ slug, meta, title, preview })=>
            <Post
              key={slug}
              date={meta.firstPublishedAt}
              title={title}
              slug={slug}
              content={preview} />)}
        </Posts>
      </FlexContainer>
    </Section>
  );
};

export default Main;
