import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Container } from 'components/misc';
import BackgroundImage from 'gatsby-background-image';

const Section = styled(BackgroundImage)`
  height: 650px;
  padding: 0 35px;
`;

const FlexContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Heading2 = styled.h2`
  font-size: 2.75rem;
`;

const StyleP = styled.p`
  margin: 20px 0;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  border: 2px solid black;
  padding: 10px 60px;
  letter-spacing: 1.5px;
`;

const HeroDescription = styled.div`
  width: 50%;
  margin-left: auto;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet} ) {
    width: 100%;
  }
`;

const Hero = ()=> {
  const imageData = useStaticQuery(graphql`{
    heroImage: file(name: {eq: "home-hero"}) {
      childImageSharp {
        fluid(maxHeight: 650, quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`);

  return (
    <Section tag="section" fluid={imageData.heroImage.childImageSharp.fluid}>
      <FlexContainer>
        <HeroDescription>
          <Heading2>Emobilność <br /> zaczyna się tutaj</Heading2>
          {/* <StyleP>There are other lights, newer stars, newer crowns. <br /> Warship engines. Another queen is coming.</StyleP> */}
          {/* <StyledLink to="/">WIĘCEJ</StyledLink> */}
        </HeroDescription>
      </FlexContainer>
    </Section>
  );
};

export default Hero;
