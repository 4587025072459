import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ArchiveDate = styled.p`
  color: ${({ theme, isActive })=> isActive ? theme.color.green : theme.color.gray};
  font-weight: ${({ isActive })=> !isActive && 'bold'};
  transition: .2s;
  margin-bottom: ${({ isActive })=> !isActive && '15px'};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const List = styled.ul`
  max-height: ${({ maxHeight })=> maxHeight};
  overflow: hidden;
  transition: .2s;
`;

const ArticleLink = styled(Link)`
  :hover {
    text-decoration: underline solid black;
  }
`;

const Accordion = ({ isActive, date, posts, setActive })=> {
  const [height, setHeight] = useState('0px');
  const content = useRef(null);

  const toggleAccordion = ()=> {
    setHeight(isActive ? `${content.current.scrollHeight}px` : '0px');
  };

  useEffect(()=> {
    toggleAccordion();
  }, [isActive]);

  const handleDateClick = ()=> {
    setActive(date);
  };

  return <div>
    <ArchiveDate onClick={handleDateClick} isActive={isActive}>{date}</ArchiveDate>
    <List maxHeight={height} ref={content}>
      {posts.map(({ title, slug })=> <li key={slug}>
        <ArticleLink to={`news/${slug}`}>{title}</ArticleLink>
      </li>)}
    </List>
  </div>;
};

export default Accordion;
