import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

const StyledPost = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h3`
  font-size: 1.285rem;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme })=> theme.color.black};
  padding: 3px 0;
`;

const PostBody = styled.div`
  margin: 20px 0;
  font-weight: 300;

  p {
    margin-bottom: 20px;
  }
`;

const Post = ({ date, title, content })=> {
  return (
    <StyledPost>
      <p>{format(new Date(date), 'dd.MM.yyyy')}</p>
      <Title>{title}</Title>
      <PostBody dangerouslySetInnerHTML={{ __html: content }} />
    </StyledPost>
  );
};

export default Post;
