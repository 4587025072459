import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import format from 'date-fns/format';

const StyledPost = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h3`
  font-size: 1.285rem;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme })=> theme.color.black};
  padding: 3px 0;
  margin-bottom: 10px;
`;

const StyledLink = styled(Link)`
  color: ${({ theme })=> theme.color.green};
  font-weight: 300;
`;

const Post = ({ date, title, slug })=> {
  return (
    <StyledPost>
      <p>{format(new Date(date), 'dd.MM.yyyy')}</p>
      <Title>{title}</Title>
      <StyledLink to={`news/${slug}`}>WIĘCEJ</StyledLink>
    </StyledPost>
  );
};

export default Post;
