import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import { FacebookShareButton,  LinkedinShareButton, TwitterShareButton } from 'react-share';
import { Col33 } from 'components/misc';

const FlexContainer = styled.div`
  display: flex;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    flex-direction: column;
  }
`;

const Col66 = styled.div`
  width: calc(100% / 3 * 2);
  padding: 0 16px;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 2.25rem;
  color: ${({ theme })=> theme.color.green};
  margin-bottom: 34px;
  font-weight: 700;
`;

const MetaData = styled.div`
  margin-bottom: 30px;
`;

const Content = styled.div`
  p {
    margin-bottom: 20px;
  }

  h2, h3 {
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: .75rem
  }
`;

const Post = ({ post })=> {
  const [shareLink, setShareLink] = useState('');

  useEffect(()=> {
    setShareLink(location.href);
  }, []);

  const { author, title, content, meta: { firstPublishedAt }} = post;

  return <>
    <FlexContainer>
      <Col66>
        <Title>{title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      </Col66>
      <Col33>
        <MetaData>
          <p>Autor: {author.name}</p>
          <p>Data: {format(new Date(firstPublishedAt), 'dd.MM.yyyy')}</p>
        </MetaData>
        <p>Udostępnij link:</p>
        <ul>
          <li><FacebookShareButton url={shareLink}>Facebook</FacebookShareButton></li>
          <li><TwitterShareButton url={shareLink}>Twitter</TwitterShareButton></li>
          <li><LinkedinShareButton url={shareLink}>LinkedIn</LinkedinShareButton></li>
        </ul>
      </Col33>
    </FlexContainer>
  </>;
};

export default Post;
