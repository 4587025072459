import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Section, Container, Col33 } from 'components/misc';
import Post from './post-preview';
import AsidePostsList from './aside-posts-list'
;

const FlexContainer = styled(Container)`
  display: flex;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    flex-direction: column;
  }
`;

const Posts = styled.div`
  width: 75%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    width: 100%;
  }
`;

const StyledCol33  = styled(Col33)`
  margin-bottom: 65px;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    margin-bottom: 16px;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme })=> theme.color.green};
  font-weight: 300;
`;

const Main = ()=> {
  const data = useStaticQuery(graphql`{
    allPosts: allDatoCmsPost(limit: 6, sort: {fields: meta___firstPublishedAt, order: DESC}, filter: {locale: {eq: "pl"}}) {
      nodes {
        slug
        title
        preview
        meta {
          firstPublishedAt
        }
      }
    }
  }`);

  return (
    <Section>
      <FlexContainer>
        <AsidePostsList />
        <Posts>
          {data.allPosts.nodes.map(({ slug, meta, title, preview })=> {
            return <StyledCol33 key={slug}>
              <Post
                date={meta.firstPublishedAt}
                title={title}
                content={preview} />
              <StyledLink to={`news/${slug}`}>WIĘCEJ</StyledLink>
            </StyledCol33>;
          })}
        </Posts>
      </FlexContainer>
    </Section>
  );
};

export default Main;
