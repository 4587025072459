import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Aside } from 'components/misc';
import { media } from 'utils';

const ShortcutList = styled.ul`
  ${media.tablet`
    display: none;
  `}
`;

const ArticleLink = styled(Link)`
  :hover {
    text-decoration: underline solid black;
  }
`;

const ArchiveLink = styled(Link)`
  color: ${({ theme })=> theme.color.green};
  font-weight: normal;
  margin: 15px 0;

  :hover {
    text-decoration: underline;
  }
`;

const activeStyle = {
  fontWeight: 'bold',
  textDecoration: 'underline solid black'
};

const AsidePostsList = ()=> {
  // TODO: language must be provided
  const data = useStaticQuery(graphql`{
    posts: allDatoCmsPost(sort: {order: DESC, fields: meta___firstPublishedAt}, limit: 10, filter: {locale: {eq: "pl"}}) {
      nodes {
        title
        slug
      }
    }
  }
  `);

  return <Aside title="Aktualności" withTitleAndShortcut>
    <ShortcutList>
      {data.posts.nodes.map(({ slug, title })=> {
        return <li key={slug}>
          <ArticleLink activeStyle={activeStyle} to={`news/${slug}`}>{title}</ArticleLink>
        </li>;
      })}
    </ShortcutList>
    <ArchiveLink to="news/archive">Archiwum</ArchiveLink>
  </Aside>;
};

export default AsidePostsList;
