import React from 'react';
import styled from 'styled-components';
import Accordion from './accordion';
import { Aside } from 'components/misc';
import { media } from 'utils';

const ShortcutList = styled.ul`
  ${media.tablet`
    display: none;
  `}
`;

const ArchiveAside = ({ setActiveDate, activeDate, posts })=> {
  return <Aside title="Archiwum">
    <ShortcutList>
      {Object.keys(posts).map((date)=>
        <Accordion
          key={date}
          isActive={activeDate === date}
          date={date}
          posts={posts[date]}
          setActive={setActiveDate} />)}
    </ShortcutList>
  </Aside>;
};

export default ArchiveAside;
